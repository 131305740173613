<template>
  <div
    :style="{ background: whiteBackground ? '#fff' : '#f7f8f8' }"
    class="g-section sbq-section"
  >
    <div class="g-header">
      <h3>算法面试三部曲，体系化课程突破面试</h3>
      <p>整个算法体系科学完善，独特的符合面试的体系课程</p>
    </div>
    <div class="container">
      <div class="sbq-cells">
        <el-row :gutter="30">
          <el-col :span="8">
            <div class="rel-card hoverUp">
              <div class="rel-pic">
                <img alt="" src="@/assets/images/qz_suanfa/ee1.png" />
              </div>
              <div class="rel-grid">
                <div class="rel-hd">求职面试算法班</div>
                <div class="rel-line"></div>
                <div class="rel-desc">
                  数据结构自我实现<br />
                  Java 源码分析<br />
                  LeetCode题目实战结合
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="rel-card hoverUp">
              <div class="rel-pic">
                <img alt="" src="@/assets/images/qz_suanfa/ee2.png" />
              </div>
              <div class="rel-grid">
                <div class="rel-hd">求职面试刷题班</div>
                <div class="rel-line"></div>
                <div class="rel-desc">
                  3000题方法总结<br />
                  套路模版汇总<br />
                  瞬间想出思路
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="rel-card hoverUp">
              <div class="rel-pic">
                <img alt="" src="@/assets/images/qz_suanfa/ee4.png" />
              </div>
              <div class="rel-grid">
                <div class="rel-hd">LeetCode视频讲解</div>
                <div class="rel-line"></div>
                <div class="rel-desc">
                  实战讲解，一天10题不是梦<br />
                  一题多解，最优解<br />
                  平均10分钟一道题讲的明明白白
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="arrow r1"></div>
        <div class="arrow r2"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "threeSteps",
  mounted() {},
  computed: {
    whiteBackground() {
      return this.$route.path == "/course/1" ? true : false;
    },
  },
};
</script>
<style scoped>
@import url("../../../assets/css/courses/common.css");
</style>
