<template>
  <!-- 求职面试算法班 -->
  <div class="">
    <div sticky-container>
      <div class="page-wrap">
        <div
          class="page-banner"
          :style="{ backgroundImage: 'url(' + img + ')' }"
        >
          <div class="container">
            <div>
              <pageSelect
                :br="br"
                :bantxt="bantxt"
                @changebr="(val) => (br = val)"
              ></pageSelect>
            </div>
            <div class="sch-title">求职面试算法班</div>
            <div class="sch-desc">
              一门颠覆传统算法教学的算法课，深入原理的理解<br />
              手写底层数据结构，到Java源码剖析，最后深化面试题目，一应俱全
            </div>
            <ul class="leet-date">
              <li>
                <div class="val">11 <span>种</span></div>
                <div class="txt">Java源码分析</div>
              </li>
              <li>
                <div class="val">50 <span>+</span></div>
                <div class="txt">算法结构解析</div>
              </li>
<!--              <li>-->
<!--                <div class="val">90 <span>天</span></div>-->
<!--                <div class="txt">答疑服务</div>-->
<!--              </li>-->
              <li>
                <div class="val">100 <span>+</span></div>
                <div class="txt">算法核心考点</div>
              </li>
<!--              <li>-->
<!--                <div class="val">5000 <span>+</span></div>-->
<!--                <div class="txt">学员好评</div>-->
<!--              </li>-->
            </ul>
            <!-- 视频 -->
            <div @click="videoShow = true" class="page-video">
              <img src="../../assets/images/scb/shipin.png" alt="" />
              <div class="v-icon"></div>
              <div class="v-title">课程简介 & 免费试听</div>
            </div>
          </div>
        </div>
        <poly-video
          v-if="videoShow"
          :br="br"
          @closeVideo="videoShow = false"
        ></poly-video>

        <div class="priceBanner">
          <div class="priceBannerArea">
            <div class="priceBannerContent">
				<img src="@/assets/imgs/priceBanner.png" alt="" />
              <div
              	class="price-tip"
                style="
                  color: rgba(0, 0, 0, 0.65);
                  text-align: center;
                  font-size: 12px;
                  position: absolute;
                  right: 82px;
                  top: 15px;
                "
              >
                立即报名，立即开始！报名即学，不浪费一分一秒！
              </div>
              <div class="priceBannerInner">
                <div class="priceLeft">
                  <div class="prices" v-if="comboData.length > 0" >
                    <span style="font-size: 28px; color: #de1306">
                      ${{
                        comboData[0].usddiscountPrice != 0
                          ? comboData[0].usddiscountPrice
                          : comboData[0].usdprice
                      }}</span
                    >
                    <span style="font-size: 16px; color: #e02020"
                      >&nbsp;/&nbsp;&yen;{{
                        comboData[0].discountPrice != 0
                          ? comboData[0].discountPrice
                          : comboData[0].price
                      }}</span
                    >
                  </div>
                  <div class="pricesTips">
                    <div class="dollar">支持美元分期付款</div>
                    <div class="huabei">支持花呗分期</div>
                  </div>
                </div>
                <div class="priceRight">
                  <el-button class="classHelp" @click="helpVisible = true"
                    >课程咨询</el-button
                  >
                  <el-button class="buyNow" @click="jumptoPrice"
                    >立即购买</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <helpClass
          :visible="helpVisible"
          @setHelpVisible="(val) => (helpVisible = val)"
        ></helpClass>

        <div
          class="sticky"
          v-sticky="stickyEnabled"
          sticky-offset="{top: 0, bottom: 30}"
          sticky-side="both"
          on-stick="onStick"
          sticky-z-index="99920"
        >
          <div class="page-navbar">
            <div class="container">
              <pageStickySelect
                :dr="dr"
                @changedr="(val) => (dr = val)"
              ></pageStickySelect>
              <Menus
                :menus="menuList"
                @TabActiveIndexChange="TabActiveIndexChange"
              />
              <div v-if="stickyToggle" class="price" style="right: -11px">
                <span
                  style="font-size: 16px; color: #de1306"
                  v-if="comboData.length > 0"
                >
                  ${{
                    comboData[0].usddiscountPrice > 0
                      ? comboData[0].usddiscountPrice
                      : comboData[0].usdprice
                  }}</span
                >
                <span style="font-size: 12px; color: #e02020"
                  >/&yen;{{
                    comboData[0].discountPrice > 0
                      ? comboData[0].discountPrice
                      : comboData[0].price
                  }}</span
                >

                <div class="priceDetail" @click="jumptoPrice">价格详情</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            TabActiveIndex === 'course_intro' ||
            TabActiveIndex === 'course_price'
          "
        >
          <div class="g-section" style="padding: 72px 0">
            <div class="g-header">
              <h3>突破海内外算法面试知识点，双向兼顾国内外面试考点</h3>
              <p>
                北美以FMAG为目标，<!--国内以BAT为目的，-->面向世界顶级IT公司
              </p>
            </div>
            <div class="container">
              <div class="hz-cells">
                <img src="../../assets/images/scb/logos.png" alt="" />
                <!--                <img src="../../assets/images/scb/course_logos.png" alt="" />-->
              </div>
            </div>
          </div>
          <div class="g-section jks-section">
            <div class="container">
              <div class="g-header">
                <h3>拒绝教科书式的生搬硬套，只教你真正能打实战的面试算法</h3>
                <p>
                  我们彻底重塑了传统数据结构算法知识体系，只为了帮你省时省力准备面试
                </p>
              </div>
              <div class="jks-row">
                <div class="jks-box jks-1">
                  <div class="inner">
                    <div class="jks-title">Java 语⾔速成起步</div>
                    <div class="jks-desc">
                      从 Java 语法讲起<br />
                      以最快的速度<br />
                      迅速了解Java机制<br />
                      天搞定， ⻢上刷题
                    </div>
                  </div>
                </div>
                <div class="jks-box jks-2">
                  <div class="inner">
                    <div class="jks-title">实现底层数据结构</div>
                    <div class="jks-desc">
                      手写底层数据结构<br />
                      自己实现Stack，Queue<br />
                      HashTable数据结构等<br />
                      北美面试会考的内容
                    </div>
                  </div>
                </div>
                <div class="jks-box jks-3">
                  <div class="inner">
                    <div class="jks-title">深入Java 源码分析</div>
                    <div class="jks-desc">
                      深入到 Java源码阶段<br />
                      分析每个数据结构<br />
                      Java源码怎么实现<br />
                      国内面试大量考
                    </div>
                  </div>
                </div>
                <div class="jks-box jks-4">
                  <div class="inner">
                    <div class="jks-title">面试题结合</div>
                    <div class="jks-desc">
                      以Leetcode 为例子<br />
                      每个数据结构<br />
                      在面试中会如何出题<br />
                      补充面试中才能出现<br />
                      学校不会讲解的算法
                    </div>
                  </div>
                </div>
                <div class="jks-box jks-5">
                  <div class="inner">
                    <div class="jks-title">体系化课程</div>
                    <div class="jks-desc">
                      对应《求职面试刷题班》<br />
                      中题型章节<br />
                      为更深入的刷题<br />
                      打下坚实基础
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="g-section">
            <div class="g-header">
              <h3>
                八大模块算法基础系统学习和实战演练，制定科学完整的课程章节
              </h3>
              <p>
                学习并掌握面试题型的底层逻辑，训练实战的套路打法，才是你的正确选择
              </p>
            </div>
            <div class="container">
              <div class="g-photo">
                <img src="../../assets/images/qz_suanfa/daotu.png" alt="" />
              </div>
            </div>
          </div>
          <div class="g-section">
            <div class="container">
              <div class="bm-td-main">
                <div class="g-header">
                  <h3>北美面试特有考点手写底层结构，我们手撕所有数据结构</h3>
                  <p>所有代码可做标准面试答案，省去大量研究实现时间</p>
                </div>
                <div class="ted-cells">
                  <el-row type="flex" align="center">
                    <el-col :span="12">
                      <div class="ted-card">
                        <el-row :gutter="10" class="ted-list">
                          <el-col :span="8">
                            <div class="ted-item ted-hd">ArrayList</div>
                            <div class="ted-item">Tree</div>
                            <div class="ted-item">Red Black Tree</div>
                          </el-col>
                          <el-col :span="8">
                            <div class="ted-item ted-hd">LinkedList</div>
                            <div class="ted-item">AVL Tree</div>
                            <div class="ted-item">Binary Index Tree</div>
                          </el-col>
                          <el-col :span="8">
                            <div class="ted-item ted-hd">Heap</div>
                            <div class="ted-item">Queue</div>
                            <div class="ted-item">Segment Tree</div>
                          </el-col>
                        </el-row>
                        <el-row :gutter="10" class="ted-list">
                          <el-col :span="8">
                            <div class="ted-item ted-hd">Stack</div>
                            <div class="ted-item">Binary Search Tree</div>
                          </el-col>
                          <el-col :span="8">
                            <div class="ted-item ted-hd">Union Find</div>
                            <div class="ted-item">Trie</div>
                          </el-col>
                          <el-col :span="8">
                            <div class="ted-item ted-hd">Deque</div>
                            <div class="ted-item">HashTable</div>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="ted-pic">
                        <img
                          src="../../assets/images/qz_suanfa/daima.png"
                          alt=""
                        />
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <div class="g-section">
            <div class="g-header">
              <h3>国内面试特有考点Java源码实现原理，剖析底层结构加深理解</h3>
              <p>深入到源码层次，让你对数据结构的理解完全不在一个层次</p>
            </div>
            <div class="container">
              <div class="shenj-cells">
                <div class="shenj-title">Java 框架的基本接口和实现：</div>
              </div>
            </div>
          </div>
          <!-- <div class="g-section sbq-section ">
                        <div class="g-header">
                            <h3>算法面试三部曲，体系化课程突破面试</h3>
                            <p>整个算法体系科学完善，最全最符合面试的体系课程</p>
                        </div>
                        <div class="container">
                            <div class="sbq-cells">
                                <el-row :gutter="30">
                                    <el-col :span="8">
                                        <div class="rel-card hoverUp">
                                            <div class="rel-pic">
                                                <img src="../../assets/images/qz_suanfa/ee1.png" alt="">
                                            </div>
                                            <div class="rel-grid">
                                                <div class="rel-hd">算法基础知识</div>
                                                <div class="rel-line"></div>
                                                <div class="rel-desc">
                                                    数据结构自我实现<br/>
                                                    Java 源码分析<br/>
                                                    LeetCode题目实战结合
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="rel-card hoverUp">
                                            <div class="rel-pic">
                                                <img src="../../assets/images/qz_suanfa/ee2.png" alt="">
                                            </div>
                                            <div class="rel-grid">
                                                <div class="rel-hd">题型技巧讲解</div>
                                                <div class="rel-line"></div>
                                                <div class="rel-desc">
                                                    3000题方法总结<br/>
                                                    套路模版汇总<br/>
                                                    顺间想出思路
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="rel-card hoverUp">
                                            <div class="rel-pic">
                                                <img src="../../assets/images/qz_suanfa/ee4.png" alt="">
                                            </div>
                                            <div class="rel-grid">
                                                <div class="rel-hd">LeetCode视频讲解</div>
                                                <div class="rel-line"></div>
                                                <div class="rel-desc">
                                                    实战讲解，一天10题不是梦<br/>
                                                    一题多解，最优解<br/>
                                                    平均10分钟一道题讲的明明白白
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <div class="arrow r1"></div>
                                <div class="arrow r2"></div>
                            </div>
                        </div>
                    </div> -->
          <three-steps></three-steps>
          <div class="g-section">
            <div class="g-header">
              <h3>Java语言五小时速成，Google Java编程规范讲解</h3>
              <p>速成刷题语言和编程规范，马上进入算法知识的正题</p>
            </div>
            <div class="container">
              <div class="jkg-cells">
                <el-row>
                  <el-col :span="12">
                    <div class="jkg-cell">
                      <div class="jkg-pic jkg1">
                        Java语言<br />
                        5小时速成
                      </div>
                      <div class="jkg-body">
                        <div class="tit">各种Java语法讲解，小白起步</div>
                        <div class="desc">
                          五小时速成Java，快速讲解<br />
                          不减少内容，学完立刻进入算法正题
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="jkg-cell">
                      <div class="jkg-pic jkg2">
                        Google Java<br />
                        编程规范
                      </div>
                      <div class="jkg-body">
                        <div class="tit">Google Java 编程规范</div>
                        <div class="desc">
                          代码风格标准一一讲解<br />
                          最标准和规整的企业级规范<br />
                          让你的代码规范严谨
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="end-logo">
                <img src="../../assets/images/qz_suanfa/endlogo.png" alt="" />
              </div>
            </div>
          </div>
          <div class="g-section">
            <div class="g-header">
              <h3>学校讲的我们讲，学校不讲面试考的我们也讲</h3>
              <p>针对面试而生的课程，以面试为主而非学校应试</p>
            </div>
            <div class="container">
              <div class="jjs-main">
                <div class="jjs-box jjs-1">
                  <div class="jjs-hd">我们的课程，独特的知识体系</div>
                  <div class="jjs-bd">
                    学校讲的深入挖掘到源码<br />
                    面试考的面面俱到，不会有任何遗漏<br />
                    两者兼顾，才能突破算法面试
                  </div>
                </div>
                <div class="jjs-box jjs-2">
                  <div class="jjs-hd">常规学校学习</div>
                  <div class="jjs-bd">
                    老学究式讲法，只讲原理不讲应用<br />
                    学完还是不会做题，和算法面试脱节<br />
                    很多面试考的不讲
                  </div>
                </div>
                <div class="jjs-box jjs-3">
                  <div class="jjs-hd">常规面试学习</div>
                  <div class="jjs-bd">
                    上来就刷题，不会的知识回头找补<br />
                    基础不牢，底层结构一考就懵<br />
                    只在面试出现题目，做不到就有洞落下
                  </div>
                </div>
                <div class="jjs-middle">
                  <div class="jjs-txt">学校教<br />面试不考</div>
                  <div class="jjs-txt">面试考<br />学校没教</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 千题录播讲解随时观看，代码升级讲师互动 -->
          <thousands-questions></thousands-questions>
          <offer></offer>
          <free-system></free-system>

          <!-- 价格方案 -->
<!--          <div class="g-section leet-jj-section" id="discountPrice">-->
<!--            <a name="tt-price"> </a>-->
<!--            <div class="g-header">-->
<!--              <h3>价格方案</h3>-->
<!--            </div>-->
<!--            <div>-->
<!--              <div class="container" v-if="comboData.length >= 3">-->
<!--                <div class="gg-yh-panel sc-yh-panel clearfix">-->
<!--                  <div class="gg-card">-->
<!--                    <el-row :gutter="80">-->
<!--                      <el-col :span="8">-->
<!--                        <div-->
<!--                          class="gg-yh-cell gg-qj-cell"-->
<!--                          style="height: inherit; padding-bottom: 20px"-->
<!--                        >-->
<!--                          <div class="gg-sfb base">-->
<!--                            <div class="gg-base">基础版</div>-->
<!--                            <div class="gg-text">求职面试算法班</div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <ul class="gg-yh-item" style="text-align: center">-->
<!--                              <li>算法基础知识</li>-->
<!--                              <li>课程目录1-9课内容</li>-->
<!--                              <li>-->
<!--                                {{ comboData[0].qaDuration / 30 }}个月有效期-->
<!--                              </li>-->
<!--                              <li>{{ comboData[0].qaDuration }}天微信群答疑</li>-->
<!--                            </ul>-->
<!--                            <div-->
<!--                              class="prices"-->
<!--                              style="-->
<!--                                font-size: 36px;-->
<!--                                text-align: center;-->
<!--                                color: rgb(222, 19, 6);-->
<!--                              "-->
<!--                            >-->
<!--                              ${{ comboData[0].usdprice-->
<!--                              }}<span class="rmbprice" style="font-size: 18px"-->
<!--                                >/￥{{ comboData[0].price }}</span-->
<!--                              >-->
<!--                            </div>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="-->
<!--                                  selectCombo = comboData[0];-->
<!--                                  buyCombo();-->
<!--                                "-->
<!--                                >立即购买</el-button-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                      <el-col :span="8">-->
<!--                        <div-->
<!--                          class="gg-yh-cell gg-qj-cell"-->
<!--                          style="height: inherit; padding-bottom: 20px"-->
<!--                        >-->
<!--                          <div class="gg-sfb upgrade">-->
<!--                            <div class="gg-base">提高版</div>-->
<!--                            <div class="gg-text">求职面试算法班</div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <ul class="gg-yh-item" style="text-align: center">-->
<!--                              <li>算法基础知识</li>-->
<!--                              <li>课程目录10-20课内容</li>-->
<!--                              <li>-->
<!--                                {{ comboData[1].qaDuration / 30 }}个月有效期-->
<!--                              </li>-->
<!--                              <li>{{ comboData[1].qaDuration }}天微信群答疑</li>-->
<!--                            </ul>-->
<!--                            <div-->
<!--                              class="prices"-->
<!--                              style="-->
<!--                                font-size: 36px;-->
<!--                                text-align: center;-->
<!--                                color: rgb(222, 19, 6);-->
<!--                              "-->
<!--                            >-->
<!--                              ${{ comboData[1].usdprice-->
<!--                              }}<span class="rmbprice" style="font-size: 18px"-->
<!--                                >/￥{{ comboData[1].price }}</span-->
<!--                              >-->
<!--                            </div>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="-->
<!--                                  selectCombo = comboData[1];-->
<!--                                  buyCombo();-->
<!--                                "-->
<!--                                >立即购买</el-button-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                      <el-col :span="8">-->
<!--                        <div-->
<!--                          class="gg-yh-cell gg-qj-cell"-->
<!--                          style="height: inherit; padding-bottom: 20px"-->
<!--                        >-->
<!--                          <div class="gg-sfb vip">-->
<!--                            <div class="gg-base">旗舰版</div>-->
<!--                            <div class="gg-text">求职面试算法班</div>-->
<!--                          </div>-->
<!--                          <div class="gg-yh-body">-->
<!--                            <ul class="gg-yh-item" style="text-align: center">-->
<!--                              <li>算法基础知识</li>-->
<!--                              <li>课程目录全部内容</li>-->
<!--                              <li>-->
<!--                                {{ comboData[2].qaDuration / 30 }}个月有效期-->
<!--                              </li>-->
<!--                              <li>{{ comboData[2].qaDuration }}天微信群答疑</li>-->
<!--                            </ul>-->
<!--                            <div-->
<!--                              class="prices"-->
<!--                              style="-->
<!--                                font-size: 36px;-->
<!--                                text-align: center;-->
<!--                                color: rgb(222, 19, 6);-->
<!--                              "-->
<!--                            >-->
<!--                              ${{ comboData[2].usdprice-->
<!--                              }}<span class="rmbprice" style="font-size: 18px"-->
<!--                                >/￥{{ comboData[0].price }}</span-->
<!--                              >-->
<!--                            </div>-->
<!--                            <div class="yh-foot">-->
<!--                              <el-button-->
<!--                                type="buy"-->
<!--                                @click="-->
<!--                                  selectCombo = comboData[2];-->
<!--                                  buyCombo();-->
<!--                                "-->
<!--                                >立即购买</el-button-->
<!--                              >-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </el-col>-->
<!--                    </el-row>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="g-section leet-jj-section" id="discountPrice">
            <a name="tt-price"> </a>
            <div class="g-header">
              <h3>价格方案</h3>
            </div>
            <div>
              <div class="container">
                <div class="gg-yh-panel sc-yh-panel clearfix">
                  <div
                      class="gg-card"
                      style="margin: 0 auto !important"
                      v-if="comboData && comboData.length > 0"
                  >
                    <el-row>
                      <el-col :span="24">
                        <div class="gg-yh-cell">
                          <div
                              :class="[
                              comboData.length > 1
                                ? 'gg-yh-grid red'
                                : 'gg-yh-grid',
                            ]"
                          >
                            <div class="gg-price">
                              ${{ comboData[0].usdprice }}
                            </div>
                            <div class="gg-y-price">
                              ￥{{ comboData[0].price }}
                            </div>
                          </div>
                          <div class="gg-yh-body">
                            <div class="tip">
                               立即报名，立即开始，录播模式<br />不浪费一分一秒，立刻学习
                            </div>
                            <ul class="gg-yh-item">
                              <li>
                                <i class="iconfont icon-dui"></i
                                >算法基础知识
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >手写底层数据结构
                              </li>
                              <li>
                                <i class="iconfont icon-dui"></i
                                >Java源码剖析
                              </li>
<!--                              <li>-->
<!--                                <i class="iconfont icon-dui"></i-->
<!--                                >90天答疑服务-->
<!--                              </li>-->
                              <li>
                                <i class="iconfont icon-dui"></i
                                >{{ comboData[0].qaDuration / 30 }}个月有效期
                              </li>
                            </ul>
                            <div class="yh-foot">
                              <el-button
                                  type="buy"
                                  @click="
                                  selectCombo = comboData[0];
                                  buyCombo();
                                "
                              >立即购买</el-button
                              >
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 相关课程推荐 -->
          <div class="g-section">
            <div class="g-header">
              <h3>—— 相关课程推荐 ——</h3>
            </div>
            <div class="container">
              <div class="rel-main" style="padding-bottom: 0">
                <el-row :gutter="30">
                  <el-col
                    :span="12"
                    v-for="(item, index) in recommendClasses"
                    :key="index"
                  >
                    <div
                      class="rel-card hoverUp"
                      @click="changePage(item.courseClassId)"
                    >
                      <div class="rel-pic">
                        <img :src="item.coverUrl" alt="" />
                      </div>
                      <div class="rel-grid">
                        <div class="rel-desc">
                          {{ item.brief }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div v-if="TabActiveIndex == 'course_question'" class="questionArea">
          <presales-consultation :objectId="4"></presales-consultation>
        </div>
        <courseMeal v-if="TabActiveIndex == 'course_meal'" id="4"></courseMeal>
        <course-detail :TabActive="TabActiveIndex" :course-i-d="courseId" />
      </div>
    </div>

    <leetDialog></leetDialog>
  </div>
</template>

<script>
import leetDialog from "@/components/leetDialog.vue";
import courseMix from "@/utils/courseMix";
import Menus from "@/components/course/menu";
import CourseDetail from "@/components/course/courseDetail";
import courseDatas from "@/utils/courseDatas";
import thousandsQuestions from "./components/thousandsQuestions";
import offer from "./components/offer";
import freeSystem from "./components/freeSystem";
import polyVideo from "./components/polyVideo";
import presalesConsultation from "./components/presalesConsultation";
import courseMeal from "./components/courseMeal";
import pageStickySelect from "./components/pageStickySelect";
import helpClass from "./components/help";
import pageSelect from "./components/pageSelect";
import threeSteps from "./components/threeSteps";
export default {
  components: {
    leetDialog,
    Menus,
    CourseDetail,
    threeSteps,
    thousandsQuestions,
    offer,
    freeSystem,
    polyVideo,
    presalesConsultation,
    courseMeal,
    pageStickySelect,
    helpClass,
    pageSelect,
  },
  mixins: [courseMix, courseDatas],
  data() {
    return {
      stickyToggle: false,
      stickyEnabled: true,
      bantxt: "求职面试算法班",
      videoShow: false,
      dr: 4,
      br: 4,
      txShow: true,
      curId: 0,
      tradio: 3,
      bzid: 0,
      Pselect: "1",
      img: require("../../assets/images/qz_suanfa/banner.jpg"),
      form: {
        region: "v1",
      },
      pp: ["ac1", "ac2", "ac3"],
      TabActiveIndex: "course_intro",
      menuList: [
        { name: "班课介绍", index: "0", tag: "course_intro" },
        { name: "班课套餐", index: "1", tag: "course_meal" },
        { name: "班课目录", index: "2", tag: "course_content" },
        { name: "用户评价", index: "3", tag: "course_comment" },
        { name: "售前咨询", index: "4", tag: "course_question" },
      ],
      recommendClasses: [], //相关课程推荐
      helpVisible: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
    changePage(index) {
      this.$router.push(`/course/${index}`);
    },
    onStick(data) {
      console.log(data);
      this.stickyToggle = data.sticked;
    },
    enter: function (e) {
      console.log(e);
      this.dr = e;
    },
    Pcselect(n) {
      this.Pselect = n;
      console.log(n);
      if (n == "1") {
        this.pp = ["ac1", "ac2", "ac3"];
      } else if (n == "2") {
        this.pp = ["ac3", "ac1", "ac2"];
      } else {
        this.pp = ["ac2", "ac3", "ac1"];
      }
    },
    brenter: function (e) {
      console.log(e);
      this.br = e;
    },
    echange(val) {
      this.bzid = val;
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    TabActiveIndexChange(index) {
      this.TabActiveIndex = index;
      console.log("------", this.TabActiveIndex);
    },
    jumptoPrice() {
      this.TabActiveIndex = "course_intro";
      this.currentIndex = "0";
      this.$nextTick(() => {
        document.documentElement.scrollTop =
          this.$el.querySelector("#discountPrice").offsetTop - 60;
      });
    },
  },
};
</script>


<style scoped lang="scss">
@import url("../../assets/css/courses/common.css");
@import url("../../assets/css/courses/4-AlgorithmCourse.css");
</style>
